import React from "react";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Routes,
} from "react-router-dom";
import "./index.css";

import Root from "./Layouts/Root.js";
import "react-toastify/dist/ReactToastify.css";

import Home from "./pages/Home";
import ErrorPage from "./pages/404";
import About from "./pages/About";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/Privacy/PrivacyPolicy";
import SpecificPrivacyPolicy from "./pages/Privacy/SpecificPrivacyPolicy";
import PrivacyPolicySingleSignOn from "./pages/Privacy/PrivacyPolicySingleSignOn";
import PrivacyNoticeRecruitment from "./pages/Privacy/PrivacyNoticeRecruitment";
import TermsAndConditions from "./pages/TermsAndConditions";
import Faqs from "./pages/FaqsFreeelancer";
import HowItWorksFindWork from "./pages/HowItWorksFindWork";
import HowItWorksHire from "./pages/HowItWorksHire";
import Careers from "./pages/Careers";
import PrivacyNoticeWebinars from "./pages/Privacy/PrivacyNoticeWebinars";
import PrivacyNoticeNewsletter from "./pages/Privacy/PrivacyNoticeNewsletter";
import PrivacyNoticeContactUs from "./pages/Privacy/PrivacyNoticeContactUs";
import PrivacyNoticeOnlinePurchase from "./pages/Privacy/PrivacyNoticeOnlinePurchase";
import PrivacyNoticePartnerPortal from "./pages/Privacy/PrivacyNoticePartnerPortal";
import PrivacyNoticeConfidentialityAgreement from "./pages/Privacy/PrivacyNoticeConfidentialityAgreement";
import EscrowManual from "./pages/EscrowManual/EscrowManual";
import Base from "./pages/EscrowManual/Base";
import HourlyBonus from "./pages/EscrowManual/HourlyBonus";
import FixedPriceService from "./pages/EscrowManual/FixedPriceService";
import FixedPriceDirect from "./pages/EscrowManual/FixedPriceDirect";
import HourlyDirectContract from "./pages/EscrowManual/HourlyDirectContract";
import FillyJobsEscrowInc from "./pages/EscrowManual/FillyJobsEscrowInc";
import FaqsClient from "./pages/FaqsClient";
import Admin from "./pages/Admin";
import AdminCategories from "./pages/Admin/Categories";
import AdminUsers from "./pages/Admin/Users";
import UserHome from "./pages/User/Home";
import Navigate from "./Components/Navigate";
import Messenger from "./pages/User/Messenger";
import Explore from "./pages/Explore";
import AdminLogin from "./pages/Admin/Login";
import AdminRegister from "./pages/Admin/Register";
import Job from "./pages/User/Job";
import JobApplications from "./pages/User/Job/Applications";
import Portfolio from "./pages/User/Portfolio";
// import AllPortfolio from "./pages/Portfolios";
import Interview from "./pages/User/Interview";
import Services from "./pages/Admin/Services";
import Service from "./pages/Service";
import ServiceDetail from "./pages/Service/ServiceDetail";
import Gallery from "./pages/User/Gallery";
import Search from "./pages/Search";
// import VideoChat from "./Components/VideoChat";
import Activate from "./pages/Activate";
import UserRoot from "./Layouts/UserRoot";
import AdminRoot from "./Layouts/AdminRoot";
import Employees from "./pages/User/Job/Employees";
import Projects from "./pages/User/Projects";
import Settings from "./pages/User/Settings";
import ServicesProfiles from "./pages/ServiceProfile/Profile";
import Credits from "./pages/Credits/Credits";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="/" errorElement={<ErrorPage />} element={<Root />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="credits" element={<Credits />} />
        <Route path="profile" element={<ServicesProfiles />} />

        <Route
          path="specific-privacy-policy"
          element={<SpecificPrivacyPolicy />}
        />

        <Route path="privacy-policy">
          <Route path="" element={<PrivacyPolicy />} />
          <Route
            path="privacy-notice-single-sign-on"
            element={<PrivacyPolicySingleSignOn />}
          />
          <Route
            path="privacy-notice-recruitment"
            element={<PrivacyNoticeRecruitment />}
          />
          <Route
            path="privacy-notice-webinars"
            element={<PrivacyNoticeWebinars />}
          />
          <Route
            path="privacy-notice-newsletter"
            element={<PrivacyNoticeNewsletter />}
          />
          <Route
            path="privacy-notice-contact-us"
            element={<PrivacyNoticeContactUs />}
          />
          <Route
            path="privacy-notice-online-purchase"
            element={<PrivacyNoticeOnlinePurchase />}
          />
          <Route
            path="privacy-notice-partner-portal"
            element={<PrivacyNoticePartnerPortal />}
          />
          <Route
            path="privacy-notice-confidentiality-agreement"
            element={<PrivacyNoticeConfidentialityAgreement />}
          />
        </Route>

        <Route path="escrow-manual" element={<Base />}>
          <Route path="" element={<EscrowManual />} />
          <Route path="hourly-bonus" element={<HourlyBonus />} />
          <Route path="hourly-direct" element={<HourlyDirectContract />} />
          <Route path="fixed-price-service" element={<FixedPriceService />} />
          <Route path="fixed-price-direct" element={<FixedPriceDirect />} />
          <Route path="escrow-inc" element={<FillyJobsEscrowInc />} />
        </Route>

        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="faqs-freelancer" element={<Faqs />} />
        <Route path="faqs-client" element={<FaqsClient />} />
        <Route path="howitworks-findwork" element={<HowItWorksFindWork />} />
        <Route path="howitworks-hire" element={<HowItWorksHire />} />
        <Route path="careers" element={<Careers />} />
        <Route path="jobs" element={<Navigate to="/jobs/fulltime" />} />
        <Route path="jobs/:type">
          <Route index element={<Explore />} />
          <Route path=":cat" element={<Explore />} />
        </Route>

        <Route path="services" element={<Service />} />
        <Route path="services/cat/:cat" element={<Service />} />
        <Route path="service/:slug" element={<ServiceDetail />} />
        <Route path="service/:slug/:sub" element={<ServiceDetail />} />
        <Route path="search/:filter/:cat">
          <Route index element={<Search />} />
        </Route>

        <Route path="activate/:code" element={<Activate />} />
      </Route>
      <Route path="/me" errorElement={<ErrorPage />} element={<UserRoot />}>
        <Route path="home" element={<UserHome />} />
        <Route path="jobs" element={<Job />} />
        <Route path="applications" element={<JobApplications />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="interviews" element={<Interview />} />
        <Route path="employees" element={<Employees />} />
        <Route path="projects" element={<Projects />} />
        <Route path="messenger" element={<Messenger />} />
      </Route>

        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/register" element={<AdminRegister />} />
      <Route path="/admin" errorElement={<ErrorPage />} element={<AdminRoot />}>
        <Route path="home" element={<Admin />} />
        <Route path="users" element={<AdminUsers />} />
        <Route path="services" element={<Services/>} />
        <Route path="categories" element={<AdminCategories />} />
      </Route>
      <Route path="/my/home" element={<Navigate to="/home" />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/settings/:page" element={<Settings />} />
      <Route path="/admin/home" element={<Admin />}></Route>
    </Route>
  )
);

const root = createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

reportWebVitals();
