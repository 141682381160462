import React, { useState, useEffect } from "react";
import { isAuth, signout } from "../Helpers/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddServiceListing from './AddServiceListing'
import logo from "../assets/image/logo.png";
import {
  showLoginScreen,
  hideLoginScreen,
  showSignupScreen,
  hideSignupScreen,
} from "../app/features/userSlice";

function Header(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.user.showLogin);
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const [showDD, setShowDD] = useState(false);
  const [active, setActive] = useState("home");
  const [loginFirst, setLoginFirst] = useState(false);
  useEffect(() => {
    if (pathname.indexOf("jobs/fulltime") > 0) {
      setActive("fulltime");
    } else if (pathname.indexOf("jobs/freelance") > 0) {
      setActive("freelance");
    } else if (pathname.indexOf("services") > 0) {
      setActive("services");
    }

    return () => {
      setActive("");
    };
  }, [pathname]);
  const [menuDisplay, setMenuDisplay] = useState(false);

  const menuToggle = () => {
    setMenuDisplay(!menuDisplay);
  };

  const handleToggleLogin = () => {
    if (login) {
      dispatch(hideLoginScreen());
    } else {
      dispatch(showLoginScreen());
    }
  };

  const handleToggleSignup = () => {
    if (login) {
      dispatch(hideSignupScreen());
    } else {
      dispatch(showSignupScreen());
    }
  };

  const handleLoginBefore = () => {
    setLoginFirst(true);
  };

  return (
    <header className="py-3 sm:px-3 lg:px-0 mx-auto relative z-10 shadow-[0px_4px_8px_0px_#8888881A]">
      
      <AddServiceListing
        show={show}
        close={() => setShow(false)}
      />
      {loginFirst && (
        <div className="fixed z-50 top-0 left-0 h-screen w-screen bg-black bg-opacity-10 flex flex-col justify-center">
          <div className="container m-auto rounded-lg bg-white p-3 w-2/3 md:w-1/3">
            <h3 className="text-lg font-semibold p-3 text-center">
              Kindly create an account or login to request a service's listing
            </h3>
            <div className="text-center mt-4 mb-6">
              <button
                onClick={() => setLoginFirst(false)}
                className="p-2 px-4 rounded-3xl text-red-500 font-bold"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setLoginFirst(false);
                  handleToggleLogin();
                }}
                className="p-2 px-4 rounded-3xl bg-sky-700 text-white"
              >
                Login/Register
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        onClick={() => setMenuDisplay(false)}
        className={
          "screen -z-20 fixed inset-0 opacity-0 bg-black h-screen hidden lg:!hidden transition-all " +
          (menuDisplay ? "!opacity-60 !z-10 !block" : "")
        }
      ></div>
      <div className="wrapper max-w-xl lg:max-w-7xl mx-auto relative flex h-16 px-5 py-4 items-center justify-between">
        <Link to="/" className="logo mx-4 ">
          <img src={logo} className="h-12" alt="logo" />
        </Link>
        <button
          onClick={menuToggle}
          className={
            "z-30 top-4 right-6 group lg:hidden " +
            (menuDisplay ? "fixed sm:absolute top-7 sm:top-4" : "absolute")
          }
        >
          <div className="relative flex items-center justify-center rounded-full w-6 h-6 transform transition-all duration-200">
            <div
              className={
                "flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center " +
                (menuDisplay ? "-rotate-[45deg]" : "")
              }
            >
              <div
                className={
                  "bg-bondi-blue h-[2px] w-1/2 rounded transform transition-all duration-300 origin-right delay-75" +
                  (menuDisplay
                    ? " -translate-y-[1px] h-[1px] -rotate-90 bg-bondi-blue"
                    : "")
                }
              ></div>
              <div
                className={
                  "bg-bondi-blue h-[2px] rounded " +
                  (menuDisplay ? "bg-bondi-blue" : "")
                }
              ></div>
              <div
                className={
                  "bg-bondi-blue h-[2px] w-1/2 rounded self-end transform transition-all duration-300 origin-left delay-75 " +
                  (menuDisplay
                    ? "translate-y-[1px] h-[1px] -rotate-90 bg-bondi-blue"
                    : "")
                }
              ></div>
            </div>
          </div>
        </button>

        <nav
          className={
            "menu text-sm lg:text-xs xl:text-sm bg-white fixed inset-0 left-1/3 z-20 h-screen px-7 translate-x-full transition-all ease-in-out duration-500 lg:left-0 lg:translate-x-0 lg:relative lg:p-0 lg:h-auto lg:flex lg:items-center " +
            (menuDisplay ? "!translate-x-0" : "")
          }
        >
          <ul className="font-normal text-center lg:text-left lg:flex lg:items-center pt-20 lg:p-0 lg:mx-3 xl:mx-9 lg:h-inherit">
            <li className="my-4 lg:my-0">
              <Link
                to="/jobs/fulltime"
                className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
              >
                Find Fulltime Jobs
              </Link>
            </li>
            <li className="my-4 lg:my-0">
              {isAuth() ? (
                <Link
                  to="/me/jobs"
                  className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
                >
                  Post Fulltime Jobs
                </Link>
              ) : (
                <button
                  onClick={handleToggleLogin}
                  className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
                >
                  Post Fulltime Jobs
                </button>
              )}
            </li>
            <li className="my-4 lg:my-0">
              <Link
                to="/jobs/freelance"
                className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
              >
                Find Freelance Jobs
              </Link>
            </li>
            <li className="my-4 lg:my-0">
              {isAuth() ? (
                <Link
                  to="/me/jobs"
                  className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
                >
                  Post Freelance Jobs
                </Link>
              ) : (
                <button
                  onClick={handleToggleLogin}
                  className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
                >
                  Post Freelance Jobs
                </button>
              )}
            </li>
            <li className="my-4 lg:my-0">
              {isAuth() ? (
                
                <button
                  onClick={() => setShow(true)}
                  className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
                >
                  List Services
                </button>
              ) : (
                <button
                  onClick={handleLoginBefore}
                  className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
                >
                  List Services
                </button>
              )}
            </li>
            <li className="my-4 lg:my-0">
              <Link
                to="/services"
                className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
              >
                Explore Services
              </Link>
            </li>
            <li className="my-4 lg:my-0">
              <Link
                to="/services"
                className="mb-5 pt-[2.5px] lg:pt-0 lg:mb-0 lg:mx-2 xl:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue transition-all"
              ></Link>
            </li>
          </ul>
          {!isAuth() && (
            <ul className="flex flex-col lg:flex-row items-center mt-12 lg:mt-0 absolute lg:relative inset-x-0 bottom-8 lg:bottom-0">
              <li className="my-2 lg:my-0">
                <button
                  onClick={handleToggleLogin}
                  className="text-bondi-blue mb-5 pt-2 lg:pt-0 lg:mb-0 lg:mx-4 lg:flex lg:items-center cursor-pointer hover:border-b-2 hover:border-bondi-blue transition-all"
                >
                  Login
                </button>
              </li>
              <li className="my-2 lg:my-0">
                <button
                  onClick={handleToggleSignup}
                  className="text-white bg-bondi-blue rounded-3xl px-3 mb-5 py-1 lg:mb-0 lg:mx-4 lg:flex lg:items-center cursor-pointer hover:text-bondi-blue hover:bg-white border border-bondi-blue transition-all"
                >
                  Join Us{" "}
                  <i className="pt-1 ml-3 font-bold">
                    <ion-icon name="arrow-forward"></ion-icon>
                  </i>
                </button>
              </li>
            </ul>
          )}

          {isAuth() && (
            <div className="relative">
              <div
                className="flex flex-row my-auto justify-center h-full"
                onClick={() => setShowDD(!showDD)}
              >
                <div className="my-auto transition ease-in-out duration-600 rounded-lg border-sky-500 border-2 font-semibold hover:text-white hover:bg-sky-500 transition ease-in-out duration-600 p-2 px-3 rounded-full">
                  <span className="fa fa-user"></span>
                </div>
              </div>
              <div
                className={`z-50 transition rounded-lg absolute top-16 mt-2 right-0 ring-1 ring-gray-200 flex flex-col bg-white w-auto shadow-lg ${
                  showDD ? "visible" : "invisible"
                }`}
              >
                <ul>
                  <li className="hover:bg-gray-100 transition">
                    <Link className="w-full block p-3" to="/me/home">
                      <span className="fa fa-user"></span>&nbsp; Home
                    </Link>{" "}
                  </li>
                  <li className="border-t-2 hover:bg-gray-100 transition">
                    <Link className="w-full block p-3" to="/me/messenger">
                      <span className="fa fa-envelope"></span>&nbsp; Messages
                    </Link>{" "}
                  </li>
                  <li className="border-t-2 bg-red-50 hover:bg-red-100 transition text-left">
                    <button
                      onClick={() => {
                        signout();
                        history("/my/home");
                      }}
                      className="w-full text-red-500 block p-3 text-left"
                    >
                      <span className="fa fa-sign-out"></span>&nbsp; Logout
                    </button>{" "}
                  </li>
                  <li className="border-t-2">
                    <div className="flex flex-row">
                      <div className="p-2 pr-1 flex justify-center">
                        <div
                          className=" m-auto h-16 w-16 rounded-full bg-black px-2"
                          style={{
                            backgroundImage: `url(${isAuth().pic})`,
                            backgroundSize: `cover`,
                            backgroundPosition: `cover`,
                          }}
                        />
                      </div>
                      <div className="p-2 pl-1 my-auto">
                        <p className="whitespace-nowrap font-semibold">
                          {isAuth().type === "agency"
                            ? isAuth().title
                            : isAuth().name}
                        </p>
                        <p className="whitespace-nowrap">
                          {isAuth().status === "agency"
                            ? "Agency"
                            : isAuth().status === "service"
                            ? "Service Professional"
                            : "Job Seeker"}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;
