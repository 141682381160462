import React, { useEffect, useState } from "react";
import image from "../../assets/image/logo.png"; //"../../assets/image/b2.jpg";
import star from "../../assets/image/logo.png"; //"../../assets/image/star.png";
import Trophy from "./svg/trophy";
import Clock from "./svg/clock";
import Location from "./svg/location";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getData, postData } from "../../Helpers/request";
import SubmitRequest from "./Quotespopup/SubmitRequest";
import SubmitReceipt from "./Quotespopup/SubmitReceipt";
import SubmitDuration from "./Quotespopup/SubmitDuration";
import ServicePurchase from "./Quotespopup/ServicePurchase";
import SendRequestQuote from "./Quotespopup/SendRequestQuote";
import SendMessage from "./Quotespopup/SendMessage";
import { toast, ToastContainer } from "react-toastify";
import { Lightbox } from "../../Components/Lightbox";

const Profile = () => {
  const profile = useSelector((state) => state.user.profile);
  const [education, setEducation] = useState(null);
  const [work, setWork] = useState(null);
  const [projects, setProjects] = useState(null)

  const [showLB, setShowLB] = useState(false)
  const [active, setActive] = useState(0)
  const [location, setLocation] = useState('')
  const [duration, setDuration] = useState('')
  const [deliveryDate, setDeliveryDate] = useState('')
  const [description, setDescription] = useState('')
  const [files, setFiles] = useState(null)

  const navigate = useNavigate();
  useEffect(() => {
    if (profile === null || !profile || profile._id === null) {
      navigate("/services");
    } else {
      getData(`/user/profile/${profile && profile._id}`)
        .then((d) => {
          // console.log(d);
          if (d.error) {
            console.log('err', d)
          } else {
            setEducation(d.education);
            setWork(d.work);
            setProjects(d.projects)
            // console.log(d.projects);
          }
          // console.log(d);
        })
        .catch((d) => console.log(d));
    }
    // console.log(profile);
  }, [profile]);
  
  const submitRequest = () => {
    let data = {location, duration, deliveryDate, description}
    console.log(data)
  }

  const saveRequest = () => {
    setActive(0)
  }
  const handlePurchase = () => {
    postData('/user/request', {location, duration, deliveryDate, description, to: profile._id})
    .then(d => {
      console.log(d)
    setActive(5)
    })
    .catch(d => {
      console.log(d)
      toast.error('There was an error submitting this request')
    })
  }
  return (
    <React.Fragment>
      <ToastContainer />
      {projects && <Lightbox show={showLB} data={projects} close={() => setShowLB(false)} />}
      {profile && <><SendMessage  active={active} to={profile._id} setActive={setActive} />
      <SubmitRequest active={active} setActive={setActive} location={location} setLocation={setLocation} />
      <SubmitDuration active={active} setActive={setActive} duration={duration} setDuration={setDuration} />
      <SendRequestQuote active={active} setActive={setActive} />
      <ServicePurchase handlePurchase={handlePurchase} active={active} setActive={setActive} description={description} setFiles={setFiles} setDescription={setDescription} saveRequest={saveRequest} />
      <SubmitReceipt active={active} setActive={setActive} saveRequest={saveRequest} /></>}
      {profile && (
        <div className="pt-8 flex flex-col md:flex-row sm:w-4/5 m-auto ">
          <div className="sm:w-full md:w-3/12">
            <div className="p-2 flex flex-col justify-center items-center text-center border-2 h-auto text-sm">
              <img className="mt-2 rounded-full w-4/12 " src={profile.pic} alt="" />
              <p className="p-2 text-lg">{profile.name}</p>
              <div className="justify-center flex w-full items-center text-center text-sm text-gray-400">
                <img className="w-3 " src={star} alt="" />
                <img className="w-3 " src={star} alt="" />
                <img className="w-3 " src={star} alt="" />
                <img className="w-3 " src={star} alt="" />
                <img className="w-3 " src={star} alt="" />
                <p className="ml-2">5.0(13 reviews)</p>
              </div>
              <div className="p-3 justify-around">
                <button onClick={() => setActive(7)} className="border-l border-r border-b border-t pl-6 pr-6 mr-2">
                  Contact
                </button>
                <button onClick={() => setActive(1)} className="border-l border-r border-b border-t bg-blue-900 text-white pl-6 pr-6 ml-2">
                  Hire
                </button>
              </div>
              <div className="p-2 flex flex-col w-full justify-center items-center">
                <Trophy />
                <p className="p-2 text-gray-400 ">Hired 5 times</p>
              </div>
              <div className="p-2 flex flex-col w-full justify-center items-center">
                <Clock />
                <p className="p-2 text-gray-400">6 years in business</p>
              </div>

              <div className="p-2 flex flex-col w-full justify-center items-center">
                <Location />
                <p className="p-2 text-gray-400 capitalize">{profile.region}, { profile.hometown}</p>
              </div>
            </div>
            <div className="mt-5 p-2 flex flex-col  border-2 h-auto text-sm">
              <div className="border-b">
                <p className="text-left font-bold">Description</p>
                <p className=" text-gray-400 text-left">
                  {profile.about}
                </p>
              </div>

              <div className="pt-2 border-b">
                <p className="text-left font-bold">Skills</p>

                <div className="grid grid-rows-3">
                  {profile.skill.map((skill, key) => (
                    <div
                      key={key}
                      className="w-1/2 rounded-md p-1  m-2 text-white bg-gray-400 text-left"
                    >
                      {skill}
                    </div>
                  ))}
                  {profile.subservice.map((skill, key) => (
                    <div
                      key={key}
                      className="w-1/2 rounded-md p-1  m-2 text-white bg-gray-400 text-left"
                    >
                      {skill}
                    </div>
                  ))}
                  {profile.specialty.split(',').map((skill, key) => (
                    <div
                      key={key}
                      className="w-1/2 rounded-md p-1  m-2 text-white bg-gray-400 text-left"
                    >
                      {skill}
                    </div>
                  ))}
                </div>
              </div>

              <div className="pt-2">
                <p className="text-left font-bold">Certifications</p>

                <div className="grid grid-rows-3">
                  <p className=" text-gray-400 text-left">
                    Lorem ipsum dolor sit amet
                  </p>
                </div>
              </div>

              {/*<div  className="border-b flex flex-col justify-left content-start">
        <p className="text-left font-bold">Skills</p>
        <div>
        <div className="rounded-md p-1 text-white bg-gray-400 text-left">lorem ipsum</div>
        </div>
    </div>*/}
            </div>
          </div>

          <div className="flex w-full flex-col md:w-2/3 border-l border-r border-b border-t md:ml-10">
            <p className="text-left font-bold p-8">{profile.name}'s Work</p>
            <div className="flex overscroll-none overflow-auto pl-8 pt-0 relative">
              {projects && projects.map(project => <img key={project._id} onClick={() => setShowLB(true)} className="hover:cursor-pointer w-36 m-2" src={project.image} alt="" />)}
              {/* <img className="w-36 m-2" src={image} alt="" />
              <img className="w-36 m-2" src={image} alt="" />
              <img className="w-36 m-2" src={image} alt="" />
              <img className="w-36 m-2" src={image} alt="" />
              <img className="w-36 m-2" src={image} alt="" />
              <img className="w-36 m-2" src={image} alt="" />
              <img className="w-36 m-2" src={image} alt="" />
              <img className="w-36 m-2" src={image} alt="" />
              <img className="w-36 m-2" src={image} alt="" /> */}
              {/* <div className="absolute bg-gray-800 text-white rounded-lg left-1/2 top-1/2 ">m</div>*/}
            </div>
            <div className="border-b">
              <p className="text-left font-bold p-8">Reviews</p>
              <div className="flex mb-4 w-3/5 pl-8 -mt-6  text-left text-xs text-gray-400">
                <img className="w-3 " src={star} alt="" />
                <img className="w-3 " src={star} alt="" />
                <img className="w-3 " src={star} alt="" />
                <img className="w-3 " src={star} alt="" />
                <img className="w-3 " src={star} alt="" />
                <p className="ml-2">5.0(13 reviews)</p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex pt-2 pl-8">
                <img className="w-6 rounded-full " src={image} alt="" />
                <p className="ml-2">Lorem Ipsum</p>
              </div>
              <p className="pl-3 m-5 text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
              </p>
              <p className="pl-8 text-sm text-gray-400">Date</p>
              <p className="pl-8 mb-5 text-sm text-gray-400">
                Was this review halpful?
              </p>
            </div>

          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Profile;
