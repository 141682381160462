import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isAuth } from "../../../Helpers/auth";
import { postData } from "../../../Helpers/request";

const SendMessage = ({ active, setActive , to}) => {
  const profile = useSelector(state => state.user.profile)
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (active === 7) {
      setShow(true);
    } else {
      setShow(false);
    }

    return () => {
      setShow(false);
    };
  }, [active]);
  
  const sendMessage = (e) => {
    e.preventDefault();
    if(!isAuth()) {
      toast.error('Login or create an account to send a message')
      return
    }
    postData("/message/send", {
      from: isAuth()._id,
      message,
      to,
    })
      .then((d) => {
        if (d.error) {
          toast.error(d.message);
          console.log(d);
        } else {
          // console.log(active.id);
          // props.socket.current.emit("send-msg", {
          //   sender: isAuth()._id,
          //   message,
          //   _id: Date.now() + Math.random() * 99999,
          //   updatedAt: Date.now(),
          //   to: active.id,
          //   name: isAuth().name,
          // });
          // setMessages([
          //   ...messages,
          //   {
          //     sender: isAuth()._id,
          //     message,
          //     _id: Date.now() + Math.random() * 99999,
          //     updatedAt: Date.now(),
          //     to: active.id,
          //   },
          // ]);
          toast.success('Your question has been submitted. You should receive a reply soon')
          setMessage("");
        }
      })
      .catch((d) => {
        toast.error(d.message);
        console.log(d);
      });
  };

  return (
    <div
      className={!show ? 'hidden' : `bg-opacity-70 bg-slate-900 fixed left-0 h-screen inset-0 overflow-hidden right-0 top-0 w-full z-50
      x-transition:enter=transition ease-out duration-100 x-transition:enter-start=opacity-0 scale-90
      x-transition:enter-end=opacity-100 scale-100 x-transition:leave=transition ease-in duration-100
      x-transition:leave-start=opacity-100 scale-100 x-transition:leave-end=opacity-0 scale-90`}
    >
      <div className="grid h-full items-center max-w-3xl mx-auto px-4 py-8 relative">
        <div className="bg-white overflow-y-auto max-h-full relative rounded-lg">
          <div className=" flex text-riight items-right justify-between p-4 rounded-t border-b">
          <div className=" flex text-riight items-right p-4 rounded-t ">
          <img className="w-20 rounded-full" src={profile.pic} alt=""/>
          <div className="ml-3"> 
          <h4 className="font-bold">Message {profile.name}</h4>
          <div className="flex items-center justify-center text-center"> 
          <p className="text-gray-500 ">Offline</p>
          <p className="bg-gray-500 rounded-full w-2 h-2 m-1"></p>
          <p className="text-gray-500">Avg. Response Time: 2hrs</p>
          </div>
         
          </div>
          </div>

            <button
              type="button"
              onClick={() => setActive(0)}
              className="bg-transparent hover:text-slate-900 inline-flex items-center p-1.5 rounded-lg text-slate-400 text-sm"
            >
              <span className="material-icons text-xl top-0 right-0 h-4/5">X</span>
            </button>
          </div>
         

          <div className="p-6 pb-8">
            <div className="grid gap-y-4">
              <form onSubmit={sendMessage} className="grid gap-4">
                <div className="flex flex-col items-start">
                  <textarea
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                    className="w-full border-2 px-8  py-4 outline-none h-52 rounded-lg"
                    placeholder="Ask Jane Doe a question or share your project details"
                  ></textarea>
                  <div className="flex w-full bg-grey-lighter pt-3">
                    <label className="w-54 flex px-4 py-2 bg-gray-300 text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                      <span className="mt-2 text-sm leading-normal">
                        Attach Files
                      </span>
                      <input type="file" class="hidden" />
                      <svg
                        className="w-8 h-8 ml-3"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                      </svg>
                    </label>
                  </div>
                </div>
              
                <div className="my-6 flex items-center justify-end">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 ml-2 hover:bg-blue-900 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm uppercase px-5 py-2 tracking-widest"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SendMessage;
