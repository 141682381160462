import React, { useEffect, useState } from "react";
import './index.css'

export const Lightbox = ({ data, show, close }) => {
  const [array, setArray] = useState([]);
  const [active, setActive] = useState(0);
  const [h, setH] = useState(0);
  const [current, setCurrent] = useState(0)
  const setActiveDiv = (n) => {
    setCurrent(n)
    let _arr = array;
    if (n === 0 && n === active) {
      _arr[n] = "slide-in-right";
      setArray(_arr);
      setActive(n);
      setH(1);
    } else {
      if (n > active) {
        _arr[active] = "slide-out-left";
        _arr[n] = "slide-in-right";
        setArray(_arr);
        setActive(n);
        setH(1);
      } else {
        _arr[active] = "slide-out-right";
        _arr[n] = "slide-in-left";
        setArray(_arr);
        setActive(n);
        setH(1);
      }
    }
  };
  useEffect(() => {
    let _x = [];
    for (let i = 0; i < data.length; i++) {
      _x.push( i === 0 ? '':"hidden");
    }
    setArray(_x);
  }, [data]);

  return data && show ? (
    <div className="h-screen w-screen fixed flex flex-row flex-wrap content-center justify-center bg-black bg-opacity-80 visible z-50 left-0 top-0">
        <button className="absolute top-4 right-4 mr-4 text-red-500 font-semibold rounded-xl p-2 px-3 bg-white" onClick={close}><span className="fa fa-close" /></button>
      <div className="m-auto">
        <button onClick={() => setActiveDiv(current > 0 ? current - 1 : array.length -1)} className="p-2 px-3 rounded-full bg-white">
          <span className="fa fa-chevron-left" />
        </button>
      </div>
      <div className="relative text-gray-900 bg-gr ay-100 container w-full overflow-hidden h-full md:h-5/6 my-auto">
        {data.map((image, i) => (
          <div
          key={i}
          style={{
            backgroundImage: `url(${image.image})`,
            backgroundSize: `cover`,
            backgroundPosition: `cover`,
          }} className={`${array[i]} p-4 py-8 h-full flex flex-col overflow-auto absolute w-full`} />
        ))}
      </div>
      <div className="m-auto">
        <button onClick={() => setActiveDiv(current < array.length - 1 ? current + 1 : 0)} className="p-2 px-3 rounded-full bg-white">
          <span className="fa fa-chevron-right" />
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};
