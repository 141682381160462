import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { io } from "socket.io-client";
import audio from "../assets/sound/just-maybe-577.mp3";

import Sidebar from "../Components/Sidebar";
import { isAuth } from "../Helpers/auth";
function useScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}
const url =
  window.location.host.indexOf("localhost") >= 0
    ? "http://localhost:1112"
    : "https://fillyapi-369713.uw.r.appspot.com/";
const notify = new Audio(audio);

const UserRoot = () => {
  useScrollToTop();
  const socket = useRef();
  const play = () => {
    notify.play();
  };

  useEffect(() => {
    socket.current = io(url);
    socket.current.emit("add-user", isAuth()._id);
  }, [url]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on("message-received", (data) => {
        play();
        toast.info("Message from " + data.name);
      });
    }
  }, [socket]);
  return (
    <>
        <ToastContainer />
      <div className="md:grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
        <div className="col-span-12 md:col-span-2 md:block">
          <Sidebar />
        </div>
        <div className=" col-span-12 md:col-span-10 w-full">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default UserRoot;
