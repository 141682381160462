import { useEffect, useState } from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";

import Adminbar from "../Components/Adminbar";
function useScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const Admin = () => {
  useScrollToTop();
  return (
    <>
      <div className="md:grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
        <div className="col-span-12 md:col-span-2 md:block">
          <Adminbar />
        </div>
        <div className=" col-span-12 md:col-span-10 w-full">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Admin;
