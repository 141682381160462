import React from 'react';
import svgImg from "./jumbo.png"

const HomeInput =(props)=>{
    return(
        <div className="bg-white">
        <div className="max-w-screen-lg mx-auto grid p-8 md:py-10 xl:py-12">
            <div className="flex items-center min-h-[500px] relative">
          
                <div
                    className="2xl:1/2 absolute rounded shadow bg-white gap-y-4 grid lg:3/5 md:w-3/4 px-3 sm:px-6 py-10 sm:4/5 xl:w-4/6">
                    <h3 className="font-extrabold text-2xl lg:text-3xl text-slate-700">
                        Find the rights talent and professionals for your projects
                    </h3>
                    <form onSubmit={props.submit}>
                        <div className="sm:flex">
                            <select id="" value={props.value} onChange={props.filter}
                                className="flex-shrink-0 inline-flex items-center p-3.5 text-sm font-medium text-slate-900 bg-slate-100 border border-slate-300 sm:rounded-l-lg hover:bg-slate-200 focus:ring-4 focus:outline-none focus:ring-slate-100">
                                <option  value="">Filter Query To...</option>
                                <option value="fulltime-jobs">Job Postings</option>
                                {/* <option value="CA">Contract</option> */}
                                <option value="service-professionals">Service</option>
                            </select>
                            <div className="relative w-full">
                                <input type="search" id="" onChange={props.change} name="query"
                                    className="block p-3.5 w-full z-20 text-sm text-slate-900 bg-slate-50 sm:rounded-r-lg border-l-slate-200 sm:border-l-slate-50 border-l border border-slate-300 focus:ring-blue-500 focus:border-blue-500 focus-within:outline-none"
                                    placeholder="What are you looking for..." required="" />
                                <button type="submit"
                                    className="absolute top-0 right-0 p-3.5 text-sm font-medium text-white bg-blue-700 sm:rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                    <span className="sr-only">Search</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div  className={` grid min-h-[500px] w-full`} style={{backgroundImage: `url(${svgImg})`, backgroundSize: `contain`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right'}} ></div>
            </div>
        </div>
    </div>
    )
}

export default HomeInput;